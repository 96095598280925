import { SleepProblemAge, SleepDisturb, SleepProblemCause } from '@lp-root/src/data/quiz-typeform.model';
import { mapNumberToSleepDisturb, mapNumberToSleepProblemAge } from '@lp-root/src/modules/quiz-b2b/quiz-b2b-questions';

export function getAssementReportProps(queryParams: {
  age?: SleepProblemAge | string;
  cause?: SleepDisturb | string;
}): {
  age: SleepProblemAge;
  cause: SleepProblemCause;
} {
  let originalAge: SleepProblemAge;
  const ageNum = parseInt(queryParams.age);
  if (Object.values(SleepProblemAge).includes(queryParams.age as SleepProblemAge)) {
    originalAge = queryParams.age as SleepProblemAge;
  } else if (typeof ageNum === 'number') {
    originalAge = mapNumberToSleepProblemAge(ageNum as 0 | 1 | 2 | 3 | 4 | 5);
  }
  const age: SleepProblemAge = mapSleepProblemAgeToSleepProblemAge(originalAge);

  let originalCause: SleepDisturb;
  const causeNum = parseInt(queryParams.cause);
  if (Object.values(SleepDisturb).includes(queryParams.cause as SleepDisturb)) {
    originalCause = queryParams.cause as SleepDisturb;
  } else if (typeof causeNum === 'number') {
    originalCause = mapNumberToSleepDisturb(causeNum as 0 | 1 | 2 | 3 | 4);
  }
  const cause: SleepProblemCause = mapSleepDisturbeToSleepProblemCause(originalCause);

  return {
    age,
    cause,
  };
}

const mapSleepProblemAgeToSleepProblemAge = (input: SleepProblemAge): SleepProblemAge => {
  switch (input) {
    case SleepProblemAge.None:
      return null;
    case SleepProblemAge.LessOneMonth:
      return SleepProblemAge.LessOneMonth;
    case SleepProblemAge.BetweenOneAndThree:
      return SleepProblemAge.BetweenOneAndThree;
    case SleepProblemAge.BetweenThreeAndSix:
      return SleepProblemAge.BetweenThreeAndSix;
    case SleepProblemAge.BetweenSixAndTwelve:
      return SleepProblemAge.BetweenSixAndTwelve;
    case SleepProblemAge.MoreThanOneYear:
      return SleepProblemAge.MoreThanOneYear;
  }
};

const mapSleepDisturbeToSleepProblemCause = (input: SleepDisturb): SleepProblemCause => {
  switch (input) {
    case SleepDisturb.Future:
      return SleepProblemCause.FutureWorry;
    case SleepDisturb.Awake:
      return SleepProblemCause.NotSleepingWorry;
    case SleepDisturb.Pain:
      return SleepProblemCause.PhysicalPain;
    case SleepDisturb.Noise:
      return SleepProblemCause.Noise;
    case SleepDisturb.Light:
      return SleepProblemCause.Light;
  }
};

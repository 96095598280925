import { SleepDisturb, SleepProblemAge } from '@lp-root/src/data/quiz-typeform.model';
import { AssesmentReport, CTAProps } from '@lp-root/src/modules/assesment/assesment-report.component';
import { Body, Col, FeaturedH2, H1, H3, Row, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import * as React from 'react';
import { getAssementReportProps } from './get-assesment-report-props';
import { SleepKindIllustrationSVG } from './sleep-kind-illustration.component';

interface QuizB2bResultsPropsQueryParams {
  score: string;
  // number
  gad: string;
  // number
  phq: string;
  insomnia: 'true' | 'false';
  age?: SleepProblemAge | string;
  cause?: SleepDisturb | string;
  company?: string;
}

interface IQuizB2bResultsProps {
  cta?: CTAProps;
}

export const QuizB2bResultsV2Row: React.FunctionComponent<IQuizB2bResultsProps> = (props) => {
  const queryParams = useQueryParams<QuizB2bResultsPropsQueryParams>();
  const params = {
    score: queryParams.score ? 100 - Math.ceil((parseInt(queryParams.score) / 24) * 100) : 1,
    company: queryParams.company,
  };
  const assesment = getAssementReportProps(queryParams);
  const patientHasInsomnia = queryParams.insomnia === 'true';
  return (
    <>
      <Row center={'xs'}>
        <Col xs={12} sm={12} md={9} lg={8}>
          <FeaturedH2>A pontuação do seu sono é {params.score} de 100.</FeaturedH2>
          <SleepKindIllustrationSVG score={params.score} />
          <Separator />
          {(parseInt(queryParams.gad) >= 3 || parseInt(queryParams.phq) >= 3) && (
            <CardWrapper>
              <H3>Observação</H3>
              <Body>
                Os resultados do seu questionário mostram que saúde mental é um ponto a ser cuidado. Portanto, conversar com um profissional
                da saúde pode ser importante. <br />
                {Math.round(params.score) <= 75
                  ? `Além disso, você sabia que é possível melhorar sua saúde mental através do seu sono? Já foi comprovado cientificamente que há uma relação bidirecional entre problemas de sono e transtornos mentais, então quem cuida da saúde mental melhora o sono, mas o inverso também é verdade: quem cuida do sono melhora a saúde mental!`
                  : ''}
              </Body>
            </CardWrapper>
          )}
        </Col>
      </Row>
      <Separator />

      <Row center={'xs'} mt>
        <Col xs={12} sm={12} md={9} lg={8}>
          {patientHasInsomnia && assesment.age ? (
            <H1>Conheça mais sobre seu sono e dicas de sono.</H1>
          ) : (
            <H1>Veja a seguir algumas dicas de sono.</H1>
          )}
          <AssesmentReport
            assesment={assesment}
            showSeeMore={false}
            hideHowWeCanHelpSection={true}
            cta={props.cta}
            showInsomniaSection={patientHasInsomnia}
          />
        </Col>
      </Row>
      <Separator />
    </>
  );
};

/**
 * if you have a mapper which maps an array of values V to K (string, number), then you can use this method to invert such mapper
 * @param values
 * @param mapper
 * @returns
 */
export function invertMapperHof<V, K extends string | number | symbol>(values: V[], mapper: (v: V) => K): (k: K) => V {
  const dict: Record<K, V> = values.reduce((acc, curr) => {
    const num = mapper(curr);
    acc[num] = curr;
    return acc;
  }, {} as Record<K, V>);
  return (value: K) => dict[value];
}

import { Body, FeaturedH2 } from '@web/atomic';
import * as React from 'react';
import { invertMapperHof } from '@global/utils/function/invert-mapper';
import {
  InitialInsomnia,
  SleepProblemAge,
  TerminalInsomnia,
  Satisfaction,
  Interference,
  OthersPerception,
  Concerned,
  WeekFrequency,
  SleepDisturb,
  Pleasure,
  Discouragement,
  Anxiety,
  ControlConcerns,
  Gender,
  MiddleInsomnia,
} from '@lp-root/src/data/quiz-typeform.model';

// Term

export const TermTitle: React.FunctionComponent = () => {
  return (
    <>
      <FeaturedH2>Termo de consentimento</FeaturedH2>
      <Body>
        Para entregar sua Pontuação do Sono e um feedback personalizado, temos que fazer perguntas pessoais sobre sua saúde. Para sua
        segurança, temos o compromisso e a obrigação legal de manter a confidencialidade e integridade de todas as informações que você nos
        fornecer. Ao submeter suas respostas, você aceita a <a href={'/sobre/politica-de-privacidade'}>Política de Privacidade</a> e os
        <a href={'/sobre/termos-e-condicoes'}> Termos e Condições da Vigilantes do Sono</a>.
      </Body>
    </>
  );
};

// 1.a
export const InitialInsomniaTitle: React.FunctionComponent = () => {
  return (
    <>
      Qual foi sua <strong>dificuldade para pegar no sono</strong>?
    </>
  );
};

export const mapInitialInsomniaToNumber = (value: InitialInsomnia): number => {
  switch (value) {
    case InitialInsomnia.None:
      return 0;
    case InitialInsomnia.Slight:
      return 1;
    case InitialInsomnia.Medium:
      return 2;
    case InitialInsomnia.Serious:
      return 3;
    case InitialInsomnia.Severe:
      return 4;
  }
};

// 1.b

export const MiddleInsomniaTitle: React.FunctionComponent = () => {
  return (
    <>
      Qual foi sua <strong>dificuldade em manter o sono</strong>? (despertares durante a noite)
    </>
  );
};

export const mapMiddleInsomniaToNumber = (value: MiddleInsomnia): number => {
  switch (value) {
    case MiddleInsomnia.None:
      return 0;
    case MiddleInsomnia.Slight:
      return 1;
    case MiddleInsomnia.Medium:
      return 2;
    case MiddleInsomnia.Serious:
      return 3;
    case MiddleInsomnia.Severe:
      return 4;
  }
};

// 1.c
export const TerminalInsomniaTitle: React.FunctionComponent = () => {
  return (
    <>
      Com que <strong>frequência você desperta</strong> muito cedo e não consegue voltar a dormir?
    </>
  );
};

export const mapTerminalInsomniaToNumber = (value: TerminalInsomnia): number => {
  switch (value) {
    case TerminalInsomnia.None:
      return 0;
    case TerminalInsomnia.Slight:
      return 1;
    case TerminalInsomnia.Medium:
      return 2;
    case TerminalInsomnia.Serious:
      return 3;
    case TerminalInsomnia.Severe:
      return 4;
  }
};

// 1.d
export const SatisfactionTitle: React.FunctionComponent = () => {
  return (
    <>
      Qual é o seu <strong>nível de satisfação</strong> com o seu padrão atual de sono?
    </>
  );
};

export const mapSatisfactionToNumber = (value: Satisfaction): number => {
  switch (value) {
    case Satisfaction.VerySatisfied:
      return 0;
    case Satisfaction.Satisfied:
      return 1;
    case Satisfaction.Neutral:
      return 2;
    case Satisfaction.Insatisfied:
      return 3;
    case Satisfaction.VeryInsatisfied:
      return 4;
  }
};

// 1.e
export const InterferenceTitle: React.FunctionComponent = () => {
  return (
    <>
      Problemas com sono <strong>interferem em suas atividades diárias</strong> (trabalhar, concentração, humor, etc.)? Se sim, com que
      intensidade?
    </>
  );
};

export const mapInterferenceToNumber = (value: Interference): number => {
  switch (value) {
    case Interference.None:
      return 0;
    case Interference.Small:
      return 1;
    case Interference.Somehow:
      return 2;
    case Interference.Great:
      return 3;
    case Interference.Extreme:
      return 4;
  }
};

// 1.f
export const OthersPerceptionTitle: React.FunctionComponent = () => {
  return (
    <>
      Com que frequência <strong>os outros percebem</strong> que problemas com sono atrapalham sua qualidade de vida?
    </>
  );
};

export const mapOthersPerceptionToNumber = (value: OthersPerception): number => {
  switch (value) {
    case OthersPerception.None:
      return 0;
    case OthersPerception.Small:
      return 1;
    case OthersPerception.Somehow:
      return 2;
    case OthersPerception.Great:
      return 3;
    case OthersPerception.Extreme:
      return 4;
  }
};

// 1.g
export const ConcernedTitle: React.FunctionComponent = () => {
  return (
    <>
      O quanto você se <strong>preocupa/estressa com problemas</strong> com sono?
    </>
  );
};

export const mapConcernedToNumber = (value: Concerned): number => {
  switch (value) {
    case Concerned.None:
      return 0;
    case Concerned.Small:
      return 1;
    case Concerned.Somehow:
      return 2;
    case Concerned.Great:
      return 3;
    case Concerned.Extreme:
      return 4;
  }
};

// 2
export const SleepProblemAgeTitle: React.FunctionComponent = () => {
  return (
    <>
      Há <strong>quanto tempo</strong> você tem dificuldades com sono?
    </>
  );
};

export const mapSleepProblemAgeToNumber = (value: SleepProblemAge): number => {
  switch (value) {
    case SleepProblemAge.None:
      return 0;
    case SleepProblemAge.LessOneMonth:
      return 1;
    case SleepProblemAge.BetweenOneAndThree:
      return 2;
    case SleepProblemAge.BetweenThreeAndSix:
      return 3;
    case SleepProblemAge.BetweenSixAndTwelve:
      return 4;
    case SleepProblemAge.MoreThanOneYear:
      return 5;
  }
};

export const mapNumberToSleepProblemAge = invertMapperHof(Object.values(SleepProblemAge), mapSleepProblemAgeToNumber);

// 3
export const WeekFrequencyTitle: React.FunctionComponent = () => {
  return (
    <>
      Quantas <strong>noites por semana</strong> você costuma ter dificuldades com sono?
    </>
  );
};

export const mapWeekFrequencyToNumber = (value: WeekFrequency): number => {
  switch (value) {
    case WeekFrequency.LessOneDay:
      return 0;
    case WeekFrequency.BetweenOneAndTwo:
      return 1;
    case WeekFrequency.BetweenThreeAndFour:
      return 2;
    case WeekFrequency.MoreThanFiveDays:
      return 3;
  }
};

// 4
export const SleepDisturbTitle: React.FunctionComponent = () => {
  return (
    <>
      Quais dos pontos abaixo <strong>costumam atrapalhar mais</strong> seu sono?
    </>
  );
};

export const mapSleepDisturbToNumber = (value: SleepDisturb): number => {
  switch (value) {
    case SleepDisturb.Future:
      return 0;
    case SleepDisturb.Awake:
      return 1;
    case SleepDisturb.Pain:
      return 2;
    case SleepDisturb.Noise:
      return 3;
    case SleepDisturb.Light:
      return 4;
  }
};

export const mapNumberToSleepDisturb = invertMapperHof(Object.values(SleepDisturb), mapSleepDisturbToNumber);

// 5.a
export const PleasureTitle: React.FunctionComponent = () => {
  return (
    <>
      Tive <strong>pouco interesse</strong> ou prazer em fazer coisas.
    </>
  );
};

export const mapPleasureToNumber = (value: Pleasure): number => {
  switch (value) {
    case Pleasure.None:
      return 0;
    case Pleasure.FewDays:
      return 1;
    case Pleasure.Majority:
      return 2;
    case Pleasure.Everyday:
      return 3;
  }
};

// 5.b
export const DiscouragementTitle: React.FunctionComponent = () => {
  return (
    <>
      Senti desânimo, desalento ou <strong>falta de esperança</strong>.
    </>
  );
};

export const mapDiscouragementToNumber = (value: Discouragement): number => {
  switch (value) {
    case Discouragement.None:
      return 0;
    case Discouragement.FewDays:
      return 1;
    case Discouragement.Majority:
      return 2;
    case Discouragement.Everyday:
      return 3;
  }
};
// 5.c
export const AnxietyTitle: React.FunctionComponent = () => {
  return (
    <>
      Me senti nervoso/a, <strong>ansioso/a</strong> ou muito tenso/a.
    </>
  );
};

export const mapAnxietyToNumber = (value: Anxiety): number => {
  switch (value) {
    case Anxiety.None:
      return 0;
    case Anxiety.FewDays:
      return 1;
    case Anxiety.Majority:
      return 2;
    case Anxiety.Everyday:
      return 3;
  }
};

// 5.d
export const ControlConcernsTitle: React.FunctionComponent = () => {
  return (
    <>
      Não fui capaz de impedir ou <strong>controlar as preocupações</strong>.
    </>
  );
};

export const mapControlConcernsToNumber = (value: ControlConcerns): number => {
  switch (value) {
    case ControlConcerns.None:
      return 0;
    case ControlConcerns.FewDays:
      return 1;
    case ControlConcerns.Majority:
      return 2;
    case ControlConcerns.Everyday:
      return 3;
  }
};

export const GenderTitle: React.FunctionComponent = () => {
  return <>Qual é o seu sexo biológico?</>;
};

export const mapGenderToNumber = (value: Gender): number => {
  switch (value) {
    case Gender.Masculin:
      return 0;
    case Gender.Feminin:
      return 1;
  }
};
